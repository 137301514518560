import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import "../App.css";
import goldcoin from "../assets/images/goldcoin.png";
import dropdown from "../assets/images/dropdowniconn.png";

CoinField.propTypes = {
  onClick: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  activeField: PropTypes.bool.isRequired,
  onMaxClick: PropTypes.func.isRequired,
  showMaxButton: PropTypes.bool,
};

export function RemoveLiquidityField1(props) {
  const { onClick, symbol, value, onChange, activeField } = props;
  return (
    <div className="container_blank">
      <div className="row">
        <div
          size="small"
          variant="extended"
          onClick={onClick}
          className="selectBox"
          style={{
            borderRadius: "0",
            boxShadow: "none",
            fontFamily: "Press Start 2P",
            minHeight: "45px",
            width: "185px",
          }}
        >
          {symbol}
          <img
            src={dropdown}
            alt="dropdown"
            className="dropdown"
            style={{
              maxWidth: "35px",
              maxHeight: "25px",
              padding: "0 5px 0 0",
            }}
          ></img>
        </div>
        <div className="BalanceBox">
          <input
            value={value}
            onChange={onChange}
            placeholder="0.0"
            disabled={!activeField}
            width={"158px"}
            className="BalanceBoxBody"
          />
          <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
        </div>
      </div>
    </div>
  );
}

export function RemoveLiquidityField2(props) {
  const { onClick, symbol } = props;

  return (
    <div className="container_blank fullwidth">
      <div className="centered">
        <div
          size="small"
          variant="extended"
          onClick={onClick}
          className="selectBox"
          style={{
            borderRadius: "0",
            boxShadow: "none",
            fontFamily: "Press Start 2P",
            minHeight: "45px",
            width: "185px",
          }}
        >
          {symbol}
          <img
            src={dropdown}
            alt="dropdown"
            className="dropdown"
            style={{
              maxWidth: "35px",
              maxHeight: "25px",
              padding: "0 5px 0 0",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default function CoinField(props) {
  const { onClick, symbol, value, onChange, activeField, onMaxClick, showMaxButton } = props;

  return (
    <div>
      <div className="selectBoxes">
        <div
          className="selectBox"
          onClick={onClick}
          style={{
            borderRadius: "0",
            boxShadow: "none",
            fontFamily: "Press Start 2P",
            minHeight: "45px",
            minWidth: "185px",
          }}
        >
          {symbol}
          <img
            src={dropdown}
            alt="dropdown"
            className="dropdown"
            style={{
              maxWidth: "35px",
              maxHeight: "25px",
              padding: "0 5px 0 0",
            }}
          ></img>
        </div>
        {/* montrer le Max button seulement si showMaxButton est true */}
        {showMaxButton && (<button
            className="maxButton"
            onClick={onMaxClick}
            style={{
              padding: "2px 5px",
              fontFamily: "Press Start 2P",
              fontSize: "10px",
              marginLeft: "10px",
              marginRight: "10px",
              cursor: "pointer",
              minWidth: "40px",
            }}
          >Max
          </button>
        )}
        <div className="BalanceBox">
          <input
            value={value}
            onChange={onChange}
            placeholder="0.0"
            disabled={!activeField}
            width={"158px"}
            className="BalanceBoxBody"
          />          
          <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
        </div>
      </div>
    </div>
  );
}
