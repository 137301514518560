import React from 'react';
import useDraggable from '../hooks/useDraggable';

const DesktopIcon = ({ icon: Icon, label, initialPosition, onOpen }) => {
  const iconSize = { width: 64, height: 64 }; // Ajustez selon la taille réelle de l'icône
  const margin = 40;
  const { position, handleDragStart, isDragging, hasMoved } = useDraggable(initialPosition, iconSize, margin);

  const handleMouseDown = (e) => {
    handleDragStart(e);
  };

  const handleClick = (e) => {
    if (!hasMoved) {
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    }
  };

  return (
    <div 
      className="desktop-icon" 
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        width: `${iconSize.width}px`,
        height: `${iconSize.height}px`,
      }}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div>
        <Icon />
      </div>
      <span className="icon-label">{label}</span>
    </div>
  );
};

export default DesktopIcon;