export const MenuItems = [
  {
    title: "Swap",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Liquidity",
    url: "/liquidity",
    cName: "nav-links",
  },
  {
    title: "Launcher",
    url: "/launcher",
    cName: "nav-links",
  },
  {
    title: " ",
    url: "/rewards",
    cName: "nav-links",
  },
];
