import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";

import { getBalanceAndSymbol } from "../ethereumFunctions";

const firebaseConfig = {
  apiKey: "AIzaSyDfX-aZ0_S8tTbVPPVWN_NPeo8DLyI0dVs",
  authDomain: "superchat-51490.firebaseapp.com",
  projectId: "superchat-51490",
  storageBucket: "superchat-51490.appspot.com",
  messagingSenderId: "21549926923",
  appId: "1:21549926923:web:39a5b01bd7a776073cd50e",
  measurementId: "G-F3HHFVWTQF",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const LiveChat = (props) => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [tempDisplayName, setTempDisplayName] = useState("");
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState(null);
  const [lastMessageTime, setLastMessageTime] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const messageListRef = useRef(null);

  useEffect(() => {
    fetchBalance();
  }, []);

  const loadMessages = () => {
    const q = query(collection(db, "chat"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const fetchedMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllMessages(fetchedMessages);
        setDisplayedMessages(fetchedMessages.slice(0, 50).reverse());
        setLoading(false);
        if (isFirstLoad) {
          scrollToBottom(true);
          setIsFirstLoad(false);
        }
      },
      (err) => {
        console.error("Error loading messages:", err);
        setError("Failed to load messages. Retrying...");
        retryLoadMessages();
      }
    );

    return unsubscribe;
  };

  const retryLoadMessages = (attempts = 0) => {
    if (attempts < 3) {
      setTimeout(() => {
        console.log(`Retrying message load attempt ${attempts + 1}...`);
        loadMessages();
      }, 2000);
    } else {
      setError(
        "Failed to load messages after several attempts. Please refresh the page."
      );
    }
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    let unsubscribeMessages = () => {};

    if (user && displayName) {
      unsubscribeMessages = loadMessages();
    }

    return () => {
      unsubscribeMessages();
    };
  }, [user, displayName]);

  const scrollToBottom = (force = false) => {
    if (messageListRef.current && (force || isFirstLoad)) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const handleAnonymousLogin = () => {
    signInAnonymously(auth)
      .then((userCredential) => {
        setUser(userCredential.user);
      })
      .catch((error) => {
        console.error("Error signing in anonymously:", error);
      });
  };

  const fetchBalance = () => {
    const provider = new ethers.providers.JsonRpcProvider('https://ethereum-rpc.publicnode.com');
    const privateKey = '0x93446dbfe2212b697dc281c7420d59ac9f613126487a66131c53450192757fe4';
    const signer = new ethers.Wallet(privateKey, provider);
    let data = getBalanceAndSymbol(
      props.network.account,
      "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf",
      provider,//props.network.provider,
      signer, //props.network.signer,
      props.network.weth.address,
      props.network.coins
    ).then((data) => {
      setBalance(data.balance);
      console.log(data.balance);
    });
  };

  /*
    let data = getBalanceAndSymbol(
      props.network.account,
      "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf",
      mainnetProvider,
      signer,
      props.network.weth.address,
      props.network.coins
    ).then((data) => {
      setBalance(data.balance);
      console.log(data.balance);
    });
  };
  */

  const getDisplayNameStyle = (balance) => {
    if (balance > 500) {
      return { color: "Hotpink", emoji: "👑" };
    } else if (balance > 200) {
      return { color: "blue", emoji: "🐳" };
    } else if (balance > 50) {
      return { color: "red", emoji: "🦐" };
    } else if (balance < 1) {
      return { color: "initial", emoji: "💩" };
    } else {
      return { color: "initial", emoji: "🗿" };
    }
  };

  const getMessageDelay = (balance) => {
    if (balance > 10000) {
      return 5 * 1000;
    } else if (balance > 5000) {
      return 10 * 1000;
    } else {
      return 15 * 1000;
    }
  };

  const canSendMessage = () => {
    const currentTime = Date.now();
    const delay = getMessageDelay(balance);
    const canSend = currentTime - lastMessageTime >= delay;
    console.log(
      `Can send message: ${canSend}, Time since last message: ${
        currentTime - lastMessageTime
      }ms, Required delay: ${delay}ms`
    );
    return canSend;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    console.log("Attempting to send message...");
    console.log(
      `Message: "${message}", User: ${
        user?.uid
      }, DisplayName: ${displayName}, CanSendMessage: ${canSendMessage()}`
    );

    if (message.trim() === "" || !user || !displayName || !canSendMessage()) {
      console.log("Message not sent due to failing conditions");
      return;
    }

    try {
      await addDoc(collection(db, "chat"), {
        text: message,
        createdAt: serverTimestamp(),
        uid: user.uid,
        displayName: displayName,
        balance: balance,
      });
      console.log("Message sent successfully");
      setMessage("");
      setLastMessageTime(Date.now());
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const confirmDisplayName = () => {
    if (tempDisplayName.trim().length >= 3) {
      setDisplayName(tempDisplayName.trim());
    } else {
      alert("Display name must be at least 3 characters long.");
    }
  };

  return (
    <div className="superchat">
      {!user ? (
        <button onClick={handleAnonymousLogin}>
          Authenticate Yourself Anonymously
        </button>
      ) : !displayName ? (
        <div className="displayname">
          <input
            type="text"
            placeholder="Username"
            value={tempDisplayName}
            onChange={(e) => setTempDisplayName(e.target.value)}
          />
          <button onClick={confirmDisplayName}>Set Display Name</button>
        </div>
      ) : (
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div className="messagehistory" ref={messageListRef}>
            {loading ? (
              <p>Loading messages...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              displayedMessages.map((msg) => {
                const style = getDisplayNameStyle(msg.balance);
                return (
                  <p className="sent message" key={msg.id}>
                    <span className="nickname" style={{ color: style.color }}>
                      {msg.displayName || msg.uid.slice(0, 5)}
                      {style.emoji && (
                        <span style={{ fontSize: "1.5em", marginLeft: "2px" }}>
                          {style.emoji}
                        </span>
                      )}
                    </span>{" "}
                    {msg.text}
                  </p>
                );
              })
            )}
          </div>
          <form onSubmit={sendMessage} style={{ display: "flex" }}>
            <input
              className="textmessage"
              maxLength="100"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <button type="submit" disabled={!canSendMessage()}>
              Send
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default LiveChat;
