import React from "react";
import Degenlogo from '../assets/images/degenlogo.png';

const DegenIcon = () => {
  return (
    <img 
      src={Degenlogo} 
      alt="Degen Logo" 
      width="55" 
      height="55" 
      style={{ pointerEvents: 'none' }}
    />
  );
};

export default DegenIcon;