import { useState, useCallback, useEffect, useRef } from 'react';

const useDraggable = (initialPosition, iconSize = { width: 64, height: 64 }, margin = 20) => {
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const dragOffset = useRef({ x: 0, y: 0 });
  const dragStart = useRef({ x: 0, y: 0 });
  const hasMovedRef = useRef(false);
  const initialRelativePosition = useRef({
    x: initialPosition.x / window.innerWidth,
    y: initialPosition.y / window.innerHeight
  });

  const constrainPosition = useCallback((pos) => {
    const maxX = window.innerWidth - iconSize.width - margin;
    const maxY = window.innerHeight - iconSize.height - margin;
    return {
      x: Math.max(margin, Math.min(pos.x, maxX)),
      y: Math.max(margin, Math.min(pos.y, maxY))
    };
  }, [iconSize.width, iconSize.height, margin]);

  const calculatePosition = useCallback(() => {
    const newX = initialRelativePosition.current.x * window.innerWidth;
    const newY = initialRelativePosition.current.y * window.innerHeight;
    return constrainPosition({ x: newX, y: newY });
  }, [constrainPosition]);

  const handleDragStart = useCallback((e) => {
    setIsDragging(true);
    dragOffset.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y
    };
    dragStart.current = { x: e.clientX, y: e.clientY };
    hasMovedRef.current = false;
  }, [position.x, position.y]);

  const handleDrag = useCallback((e) => {
    if (isDragging) {
      const newPosition = constrainPosition({
        x: e.clientX - dragOffset.current.x,
        y: e.clientY - dragOffset.current.y
      });
      setPosition(newPosition);
      initialRelativePosition.current = {
        x: newPosition.x / window.innerWidth,
        y: newPosition.y / window.innerHeight
      };

      const dx = e.clientX - dragStart.current.x;
      const dy = e.clientY - dragStart.current.y;
      if (Math.sqrt(dx*dx + dy*dy) > 5) {
        hasMovedRef.current = true;
      }
    }
  }, [isDragging, constrainPosition]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleResize = useCallback(() => {
    setPosition(calculatePosition());
  }, [calculatePosition]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', handleDragEnd);
    } else {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', handleDragEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isDragging, handleDrag, handleDragEnd]);

  // Initial position setup
  useEffect(() => {
    handleResize();
  }, [handleResize]);

  return { position, handleDragStart, isDragging, hasMoved: hasMovedRef.current };
};

export default useDraggable;