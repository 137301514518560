import React from "react";
import Clippylogo from '../assets/images/clippylogo.png';

const ClippyIcon = () => {
  return (
    <img 
      src={Clippylogo} 
      alt="Clippy Logo" 
      width="50" 
      height="50" 
      style={{ pointerEvents: 'none' }}
    />
  );
};

export default ClippyIcon;