import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "../App.css";
import goldcoin from "../assets/images/goldcoin.png";
import remover from "../assets/images/Remove.png";
import {
  getBalanceAndSymbol,
  getStats,
  claimRewards,
  getTransactions,
} from "../ethereumFunctions";
import CoinDialog from "../CoinSwapper/CoinDialog";
import WrongNetwork from "../Components/wrongNetwork";
import COINS from "../constants/coins";
import * as chains from "../constants/chains";
import CoinField from "../CoinSwapper/CoinField";

function ProgressBar(props) {
  const MAX_VALUE = 30000;
  const [progressValue, setProgressValue] = useState(0);
  const [userRewards, setUserRewards] = useState(0);
  const [allTimeRewards, setAllTimeRewards] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(
    "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf"
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Current network:", props.network);

    const fetchRewards = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (!props.network) {
          throw new Error("Network not available");
        }
        const newValue = await fetchR(props.network);
        setProgressValue(newValue);
      } catch (error) {
        console.error("Error fetching rewards:", error);
        setError(`Failed to fetch transaction count: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRewards();
    const fetchInterval = setInterval(fetchRewards, 5000);
    return () => clearInterval(fetchInterval);
  }, [selectedToken, props.network]);

  async function fetchR(network) {
    if (!network || !network.router || !network.factory || !network.signer) {
      console.error("Missing network properties:", network);
      throw new Error("Network configuration is incomplete");
    }

    try {
      //console.log("fetchingCount", { network, selectedToken });
      const provider = new ethers.providers.JsonRpcProvider('https://ethereum-rpc.publicnode.com');
      const privateKey = '0x93446dbfe2212b697dc281c7420d59ac9f613126487a66131c53450192757fe4';
      const signer = new ethers.Wallet(privateKey, provider);

      const txns = await getTransactions(
        selectedToken,
        signer
      );
      //console.log("Fetched transactions:", txns);
      return txns;
    } catch (error) {
      console.error("Error fetching transactions:", error);
      throw error;
    }
  }

  const progressPercentage = Math.min((progressValue / MAX_VALUE) * 100, 100);

  return (
    <div className="win98-progress-container">
      <div className="win98-progress-label">Targets</div>
      <span className="targetlist">
        <p className="target">
          Layer
          <br />
          2s
        </p>
        <p className="target">
          Feeless
          <br />
          Flashloans
        </p>
        <p className="target">
          CMC
          <br />
          Listing
        </p>
        <p className="target">Lending</p>

        <p className="target">
          CEX
          <br />
          Listing
        </p>
        <p className="target">Futures</p>
      </span>
      {/* <span className="verticalbars">
        <div className="verticalbar1"></div>
        <div className="verticalbar2"></div>
        <div className="verticalbar3"></div>
        <div className="verticalbar4"></div>
        <div className="verticalbar5"></div>
        <div className="verticalbar6"></div>
      </span> */}
      <div className="bar">
        <div
          className="progress"
          style={{ width: `${progressPercentage}%` }}
        ></div>
        <span className="dotlist">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dotred"></div>
          <div className="dot"></div>
          <div className="dotred"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dotred"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dotred"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dotred"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dotred"></div>
        </span>
      </div>
      <section className="barsection">
        <div className="smallbar">0K</div>
        <div className="smallbar">5K</div>
        <div className="smallbar">10K</div>
        <div className="smallbar">15K</div>
        <div className="smallbar">20K</div>
        <div className="smallbar">25K</div>
        <div className="smallbar">30K</div>
      </section>
      <div className="win98-progress-value">
        {error ? error : `${progressValue} txns`}
      </div>
    </div>
  );
}

export default ProgressBar;
