import React from "react";
import powershell from "../assets/images/powershell.png";
const PowershellIcon = () => {
  return (
    <img
      src={powershell}
      alt="Powershell Logo"
      width="50"
      height="50"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default PowershellIcon;
