import React from "react";
import Ninjalogo from '../assets/images/ninjaicon.png';

const NinjaIcon = () => {
  return (
    <img 
      src={Ninjalogo} 
      alt="Ninja Logo" 
      width="50" 
      height="50" 
      style={{ pointerEvents: 'none' }}
    />
  );
};

export default NinjaIcon;