import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getBalanceAndSymbol, create } from "../ethereumFunctions";
import { launch, setMeta } from "./LiquidityFunctions";
import CoinField from "../CoinSwapper/CoinField";
import CoinDialog from "../CoinSwapper/CoinDialog";
import "../App.css";
import goldcoin from "../assets/images/goldcoin.png";
import moon from "../assets/images/moon.png";
import monke from "../assets/images/gooluck.gif";
import rover from "../assets/images/rover.gif";
import clippy from "../assets/images/clippybounce.gif";
import question from "../assets/images/interrogation.png";

function LiquidityDeployer(props) {
  const [field1Value, setField1Value] = useState(""); // CoinField 1
  const [field2Value, setField2Value] = useState(""); // CoinField 2

  const [inputBuyLiqValue, setInputBuyLiqValue] = useState(""); // Buy Liq
  const [inputSellCreatorValue, setInputSellCreatorValue] = useState(""); // SellCreator
  const [inputBuyCreatorFeeValue, setInputBuyCreatorFeeValue] = useState(""); // BuyCreator Fee
  const [inputSellCreatorFeeValue, setInputSellCreatorFeeValue] = useState(""); // SellCreator Fee
  const [inputProtocolAddressValue, setInputProtocolAddressValue] =
    useState(""); // Protocol Address

  const [inputWebsiteURL, setInputWebsiteURL] = useState(""); // Website URL
  const [inputTokenDescription, setInputTokenDescription] = useState(""); // Description
  const [inputImageURL, setInputImageURL] = useState(""); // Image URL
  const [inputChatURL, setInputChatURL] = useState(""); // Chat URL
  const [inputSocialURL, setInputSocialURL] = useState(""); // Social URL
  const [dialog1Open, setDialog1Open] = useState(false);
  const [dialog2Open, setDialog2Open] = useState(false);

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [supply, setSupply] = useState("");
  const [vistaOnly, setVistaOnly] = useState("");

  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  const [coin1, setCoin1] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });

  const [launchError, setLaunchError] = useState(false);

  const [displayAddress, setDisplayAddress] = useState("");

  // Dummy function pour ladresse
  const updateAddress = async () => {
    await create(
      name,
      symbol,
      supply,
      vistaOnly,
      props.network.signer
    ).then((value) => setDisplayAddress(value))    
  };

  const handleCoinFieldChange = {
    field1: (e) => setField1Value(e.target.value),
    field2: (e) => setField2Value(e.target.value),
  };

  const handleInputChange = {
    buyLiq: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputBuyLiqValue(value);
      }
    },
    sellCreator: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputSellCreatorValue(value);
      }
    },
    buyCreatorFee: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputBuyCreatorFeeValue(value);
      }
    },
    sellCreatorFee: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputSellCreatorFeeValue(value);
      }
    },
    protocolAddress: (e) => setInputProtocolAddressValue(e.target.value),
    websiteURL: (e) => setInputWebsiteURL(e.target.value),
    description: (e) => setInputTokenDescription(e.target.value),
    imageURL: (e) => setInputImageURL(e.target.value),
    chatURL: (e) => setInputChatURL(e.target.value),
    InputSocialUrl: (e) => setInputSocialURL(e.target.value),
  };

  const onToken1Selected = (address) => {
    setDialog1Open(false);
    if (address === coin2.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const onToken2Selected = (address) => {
    setDialog2Open(false);
    if (address === coin1.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setField1Value(field2Value);
    setField2Value(field1Value);
  };

  const deploy = async () => {
    if (
      !inputBuyLiqValue ||
      !inputSellCreatorValue ||
      !inputBuyCreatorFeeValue ||
      !inputSellCreatorFeeValue ||
      !inputProtocolAddressValue ||
      !coin1.address ||
      !coin2.address
    ) {
      console.error(
        "Error: All input fields must be filled and tokens must be selected."
      );
      setLaunchError(true);
      return;
    }

    try {
      const result = await launch(
        coin1.address,
        coin2.address,
        field1Value,
        field2Value,
        "0",
        "0",
        props.network.router,
        props.network.account,
        props.network.signer,
        inputBuyLiqValue,
        inputSellCreatorValue,
        inputBuyCreatorFeeValue,
        inputSellCreatorFeeValue,
        inputProtocolAddressValue,
        props.network.signer
      );
      console.log("Launch result:", result);
    } catch (error) {
      console.error("Launch error:", error);
      setLaunchError(true);
    }
  };

  const handleDummyButtonClick = async () => {
    if (
      !inputWebsiteURL ||
      !inputTokenDescription ||
      !inputImageURL ||
      !inputChatURL ||
      !inputSocialURL ||
      !coin1.address ||
      !coin2.address
    ) {
      console.error(
        "Error: All input fields must be filled and tokens must be selected."
      );
      setLaunchError(true);
      return;
    }

    try {
      const result = await setMeta(
        coin1.address,
        coin2.address,
        inputWebsiteURL,
        inputTokenDescription,
        inputImageURL,
        inputChatURL,
        inputSocialURL,
        props.network.factory,
        props.network.signer
      );
      console.log("result:", result);
    } catch (error) {
      console.error("Launch error:", error);
      setLaunchError(true);
    }
  };

  return (
    <div className="paddingBot">
      <div className="window scrollcontainer lg-padding overflowscroll">
        {/* Dialog Windows */}
        <CoinDialog
          open={dialog1Open}
          onClose={onToken1Selected}
          coins={props.network.coins}
          signer={props.network.signer}
        />
        <CoinDialog
          open={dialog2Open}
          onClose={onToken2Selected}
          coins={props.network.coins}
          signer={props.network.signer}
        />
        <fieldset className="launchsafetokensection">
          <legend>Create Safe Token</legend>
          <section className="valueselection">
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">Name</p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Name"
                  className="BalanceBoxBody"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">Symbol</p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Symbol"
                  className="BalanceBoxBody"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                />
              </div>
            </span>
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">Supply</p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Supply"
                  className="BalanceBoxBody"
                  value={supply}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setSupply(value);
                    }
                  }}
                />
              </div>
              <img src={rover} alt="launch" className="rover" />
            </span>
          </section>
          <p className="subtitles onlyether">
            Trade only on Ethervista and benefit from all the fees ?
          </p>
          <div className="launchsafetoken">
            <label style={{ display: "block", marginBottom: "5px" }}>
              <input
                type="checkbox"
                checked={vistaOnly === "yes"}
                onChange={(e) => {
                  setVistaOnly(e.target.checked ? "yes" : "no");
                }}
                className={vistaOnly === "yes" ? "pressed" : "outward"}
              />
              <span
                className="boolean"
                style={{
                  margintop: "10px",
                  color: vistaOnly === "yes" ? "lightgray" : "black",
                  border: vistaOnly === "yes" ? "2px solid" : "2px solid",
                  borderTopColor: vistaOnly === "yes" ? "#808080" : "#dfdfdf",
                  borderLeftColor: vistaOnly === "yes" ? "#808080" : "#dfdfdf",
                  borderRightColor: vistaOnly === "yes" ? "#dfdfdf" : "#808080",
                  borderBottomColor:
                    vistaOnly === "yes" ? "#dfdfdf" : "#808080",
                  marginRight: "20px",
                }}
              >
                Yes
              </span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={vistaOnly === "no"}
                onChange={(e) => {
                  setVistaOnly(e.target.checked ? "no" : "yes");
                }}
                className={vistaOnly === "no" ? "pressed" : "outward"}
              />
              <span
                className="boolean"
                style={{
                  color: vistaOnly === "no" ? "lightgray" : "black",
                  border: vistaOnly === "no" ? "2px solid" : "2px solid",
                  borderTopColor: vistaOnly === "no" ? "#808080" : "#dfdfdf",
                  borderLeftColor: vistaOnly === "no" ? "#808080" : "#dfdfdf",
                  borderRightColor: vistaOnly === "no" ? "#dfdfdf" : "#808080",
                  borderBottomColor: vistaOnly === "no" ? "#dfdfdf" : "#808080",
                }}
              >
                No, I want to stay poor
              </span>
            </label>
          </div>
          <button
            onClick={async () => {
                await updateAddress();
            }}
            className="metadatabuttoncontainer"
          >
            <h6 className="metadatabutton">Create</h6>
          </button>
          <div>
            <p className="lightweight">
              Address will appear here: {displayAddress}
            </p>
          </div>
        </fieldset>
        <fieldset>
          <legend>Launch</legend>

          <div item class="window-body graybg">
            <CoinField
              activeField={true}
              value={field1Value}
              onClick={() => setDialog1Open(true)}
              onChange={handleCoinFieldChange.field1}
              symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
            />
          </div>
          <div item class="window-body graybg">
            <CoinField
              activeField={true}
              value={field2Value}
              onClick={() => setDialog2Open(true)}
              onChange={handleCoinFieldChange.field2}
              symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
            />
          </div>
          <legend className="legend2">Fees (USD value)</legend>

          <section className="valueselection">
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">
                LP-Fees
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Buy"
                  className="BalanceBoxBody"
                  value={inputBuyLiqValue}
                  onChange={handleInputChange.buyLiq}
                />
                <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
              </div>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Sell"
                  className="BalanceBoxBody"
                  value={inputSellCreatorValue}
                  onChange={handleInputChange.sellCreator}
                />
                <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
              </div>
            </span>

            <span className="protocoladdress primary">
              <p className="subtitles correctFont NoPadding primary NoMargin">
                Protocol Address{" "}
                <img
                  src={question}
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                  alt="Gold Coin"
                  className="help_button"
                ></img>
                {tooltipVisible && (
                  <p
                    style={{
                      display: "block",
                      position: "absolute",
                      fontSize: "10px",
                      width: "350px",
                      top: -20,
                      left: -100,
                      opacity: 1,
                      backgroundColor: "#c0c0c0",
                      color: "55555",
                      textAlign: "center",
                      zIndex: 1000,
                      lineHeight: 2,
                      border: "3px solid #555",
                      padding: "10px",
                    }}
                  >
                    Protocol address is either a<br /> smart contract which
                    handles
                    <br />
                    the fee or the creator's address
                  </p>
                )}
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Address"
                  className="BalanceBoxBody"
                  value={inputProtocolAddressValue}
                  onChange={handleInputChange.protocolAddress}
                />
              </div>
            </span>
          </section>
          <section className="valueselection">
            <p className="subtitles correctFont NoMargin NoPadding secondary">
              Protocol Address
            </p>

            <div className="BalanceBoxlg secondary">
              <input
                placeholder="Address"
                className="BalanceBoxBody"
                value={inputProtocolAddressValue}
                onChange={handleInputChange.protocolAddress}
              />
            </div>
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">
                Protocol fees
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Buy"
                  className="BalanceBoxBody"
                  value={inputBuyCreatorFeeValue}
                  onChange={handleInputChange.buyCreatorFee}
                />
                <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
              </div>

              <div className="BalanceBoxlg">
                <input
                  placeholder="Sell"
                  className="BalanceBoxBody"
                  value={inputSellCreatorFeeValue}
                  onChange={handleInputChange.sellCreatorFee}
                />
                <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
              </div>
            </span>
            <img src={monke} alt="launch" className="monke" />
          </section>
          <button onClick={deploy} className="swapbutton">
            <h6 className={`BalanceBoxBody ${launchError ? "error-text" : ""}`}>
              LAUNCH
            </h6>
            <img
              src={moon}
              alt="launch"
              className="goldCoin NoMargin NoPadding"
            />
          </button>
        </fieldset>

        <fieldset className="setmetadata">
          <legend>Set Metadata</legend>
          <section className="valueselection">
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">
                Website URL
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="URL"
                  className="BalanceBoxBody"
                  value={inputWebsiteURL}
                  onChange={handleInputChange.websiteURL}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">
                Description
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Desc"
                  className="BalanceBoxBody"
                  value={inputTokenDescription}
                  onChange={handleInputChange.description}
                />
              </div>
              <img src={clippy} alt="launch" className="clippy" />
            </span>
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">Image</p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Image URL"
                  className="BalanceBoxBody"
                  value={inputImageURL}
                  onChange={handleInputChange.imageURL}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">
                Chat URL
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="ChatURL"
                  className="BalanceBoxBody"
                  value={inputChatURL}
                  onChange={handleInputChange.chatURL}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">
                Social URL
              </p>
              <div className="BalanceBoxlg">
                <input
                  placeholder="Social"
                  className="BalanceBoxBody"
                  value={inputSocialURL}
                  onChange={handleInputChange.InputSocialUrl}
                />
              </div>
            </span>
          </section>

          <button
            onClick={handleDummyButtonClick}
            className="metadatabuttoncontainer"
          >
            <h6 className="metadatabutton">Set Metadata</h6>
          </button>
        </fieldset>
      </div>
    </div>
  );
}

export default LiquidityDeployer;
