import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import Web3Provider from "./network";
import NarBar from "./NavBar/NavBar";
import CoinSwapper from "./CoinSwapper/CoinSwapper";
import { Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Liquidity from "./Liquidity/Liquidity";
import Rewards from "./Liquidity/Rewards";
import Analytics from "./Liquidity/Analytics";
import Launcher from "./Liquidity/Launcher";
import lightbg from "./assets/images/lightTheme.jpg";
import ethericon from "./assets/images/logo.png";
import LiveChat from "./Components/LiveChat";
import ProgressBar from "./Components/ProgressBar";
import whitepaper from "./assets/images/whitepaper.pdf";
import superchat from "./assets/images/superchatgif.gif";
import DesktopIcon from "./Components/DesktopIcon";
import LimeIcon from "./Components/LimeIcon";
import PowershellIcon from "./Components/PowershellIcon";
import ClippyIcon from "./Components/ClippyIcon";
import NinjaIcon from "./Components/NinjaIcon";
import MinesweeperIcon from "./Components/MinesweeperIcon";
import RoverIcon from "./Components/RoverIcon";
import DegenIcon from "./Components/DegenIcon";
import ConnectionStatus from "./ConnectionStatus";

const calculateInitialPosition = (xPercent, yPixels) => {
  return {
    x: (window.innerWidth * xPercent) / 100,
    y: yPixels
  };
};

const useWindowDrag = (ref) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const offsetX = e.clientX - ref.current.getBoundingClientRect().left;
    const offsetY = e.clientY - ref.current.getBoundingClientRect().top;
    setDragOffset({ x: offsetX, y: offsetY });
    document.body.style.userSelect = "none";
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        const newX = e.clientX - dragOffset.x;
        const newY = e.clientY - dragOffset.y;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const containerWidth = ref.current.offsetWidth;
        const containerHeight = ref.current.offsetHeight;

        const maxLeftPosition = 0;
        const maxRightPosition = screenWidth - containerWidth;
        const maxBottomPosition = screenHeight - 35 - containerHeight;

        const boundedX = Math.max(
          Math.min(newX, maxRightPosition),
          maxLeftPosition
        );
        const boundedY = Math.max(Math.min(newY, maxBottomPosition), 0);

        ref.current.style.left = boundedX + "px";
        ref.current.style.top = boundedY + "px";
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.style.userSelect = "auto";
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, dragOffset]);

  return handleMouseDown;
};

const getInitialPosition = (key) => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const containerWidth = 600;
  const containerHeight = 400;

  if (screenWidth <= 800) {
    return { left: 0, top: 0 };
  }

  const centerX = (screenWidth - containerWidth) / 2;
  const centerY = (screenHeight - containerHeight) / 2;

  switch (key) {
    case "container1":
      return {
        left: centerX,
        top: 25,
      };
    case "container2":
      return {
        left: centerX + 50,
        top: centerY + 50,
      };
    case "container3":
      return {
        left: 0,
        top: 500,
      };
    case "container4":
      return {
        left: centerX - 100,
        top: centerY - 50,
      };
    case "container5":
      return {
        left: centerX + 100,
        top: centerY - 50,
      };
    case "container6":
      return {
        left: centerX,
        top: centerY + 100,
      };
    case "container7":
      return {
        left: screenWidth - 200 - containerWidth,
        top: 50,
      };
    case "container8":
      return {
        left: 50,
        top: 100,
      };
    default:
      return { left: 0, top: 0 };
  }
};

const App = () => {

  const [windowVisibility, setWindowVisibility] = useState({
    container1: true,
    container2: false,
    container3: false,
    container4: false,
    container5: false,
    container6: false,
    container7: false,
    container8: false,
  });
  const [showBackgroundImage, setShowBackgroundImage] = useState(true);

  const containerRefs = {
    container1: useRef(null),
    container2: useRef(null),
    container3: useRef(null),
    container4: useRef(null),
    container5: useRef(null),
    container6: useRef(null),
    container7: useRef(null),
    container8: useRef(null),
  };

  const [containerSizes, setContainerSizes] = useState({
    container1: { width: 600, height: 400 },
    container2: { width: 600, height: 400 },
    container3: { width: 600, height: 400 },
    container4: { width: 600, height: 400 },
    container5: { width: 600, height: 400 },
    container6: { width: 600, height: 400 },
    container7: { width: 600, height: 400 },
    container8: { width: 600, height: 400 },
  });

  useEffect(() => {
    const updateContainerSize = (key) => {
      if (containerRefs[key].current) {
        const { offsetWidth, offsetHeight } = containerRefs[key].current;
        setContainerSizes((prev) => ({
          ...prev,
          [key]: { width: offsetWidth, height: offsetHeight },
        }));
      }
    };

    Object.keys(containerRefs).forEach((key) => {
      if (windowVisibility[key]) {
        updateContainerSize(key);
      }
    });
  }, [windowVisibility]);

  const [delayedVisibility, setDelayedVisibility] = useState({
    container1: true,
    container2: false,
    container3: false,
    container4: false,
    container5: false,
    container6: false,
    container7: false,
    container8: false,
  });

  const toggleWindow = (windowName) => {
    setWindowVisibility((prev) => {
      const newVisibility = {
        ...prev,
        [windowName]: !prev[windowName],
      };

      // If we're showing the window, delay the visibility
      if (newVisibility[windowName]) {
        setTimeout(() => {
          setDelayedVisibility((prevDelayed) => ({
            ...prevDelayed,
            [windowName]: true,
          }));
        }, 50); // 50ms delay should be enough
      } else {
        // If we're hiding the window, update delayed visibility immediately
        setDelayedVisibility((prevDelayed) => ({
          ...prevDelayed,
          [windowName]: false,
        }));
      }

      return newVisibility;
    });
  };

  const handleMouseDown1 = useWindowDrag(containerRefs.container1);
  const handleMouseDown2 = useWindowDrag(containerRefs.container2);
  const handleMouseDown3 = useWindowDrag(containerRefs.container3);
  const handleMouseDown4 = useWindowDrag(containerRefs.container4);
  const handleMouseDown5 = useWindowDrag(containerRefs.container5);
  const handleMouseDown6 = useWindowDrag(containerRefs.container6);
  const handleMouseDown7 = useWindowDrag(containerRefs.container7);
  const handleMouseDown8 = useWindowDrag(containerRefs.container8);

  const getWindowStyle = (key) => {
    const screenWidth = window.innerWidth;
    const { left, top } = getInitialPosition(key);

    if (screenWidth <= 800) {
      return {
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 10,
      };
    }

    return {
      left: `${left}px`,
      top: `${top}px`,
      zIndex: 10,
    };
  };

  const toggleBackgroundImage = () => {
    console.log("To Dark Mode and Beyond");
    setShowBackgroundImage(!showBackgroundImage);
  };

  const [networkState, setNetworkState] = useState(null);

  const web3ProviderContent = React.useMemo(() => {
    return (
      <Web3Provider
        render={(network) => {
          setNetworkState(network);
          return (
            <div className="maincontainer">
              <NarBar />
              <Route exact path="/">
                <CoinSwapper network={network} />
              </Route>

              <Route exact path="/liquidity">
                <Liquidity network={network} />
              </Route>

              <Route exact path="/launcher">
                <Launcher network={network} />
              </Route>

              <Route exact path="/rewards">
                <Rewards network={network} />
              </Route>

              <Route exact path="/analytics">
                <Analytics network={network} />
              </Route>

              <Route exact path="/livechat">
                <LiveChat network={network} />
              </Route>

              <Route exact path="/ProgressBar">
                <ProgressBar network={network} />
              </Route>

              <Route exact path="/flashloans">
                <Rewards network={network} />
              </Route>
              
              <Route exact path="/ConnectionStatus">
                <ConnectionStatus network={network} />
              </Route>
            </div>
          );
        }}
      />
    );
  }, []);
  // Dependencies array is empty, meaning it won't recompute

  return (
    
    <>
      <ProgressBar network={networkState} />

      <DesktopIcon
        icon={LimeIcon}
        label="Lime"
        initialPosition={calculateInitialPosition(90, 20)}
        onOpen={() => window.open("https://lime.audio/", "_blank")}
      />

      <DesktopIcon
        icon={PowershellIcon}
        label="Powershell"
        initialPosition={calculateInitialPosition(90, 120)}
        onOpen={() => window.open("https://powershelltoken.com/", "_blank")}
      />

      <DesktopIcon
        icon={ClippyIcon}
        label="Clippy"
        initialPosition={calculateInitialPosition(90, 220)}
        onOpen={() => window.open("https://vistaclippy.xyz/", "_blank")}
      />

      <DesktopIcon
        icon={NinjaIcon}
        label="Ninja"
        initialPosition={calculateInitialPosition(90, 320)}
        onOpen={() => window.open("https://www.ninjacat.xyz/", "_blank")}
      />

      <DesktopIcon
        icon={MinesweeperIcon}
        label="Mine Sweeper"
        initialPosition={calculateInitialPosition(90, 420)}
        onOpen={() => window.open("https://www.mine-sweeper.fun/", "_blank")}
      />

      <DesktopIcon
        icon={RoverIcon}
        label="Rover"
        initialPosition={calculateInitialPosition(90, 520)}
        onOpen={() => window.open("https://www.vistarover.xyz/", "_blank")}
      />

      <DesktopIcon
        icon={DegenIcon}
        label="Degen"
        initialPosition={calculateInitialPosition(80, 20)}
        onOpen={() => window.open("https://degenads.com/vista/", "_blank")}
      />

      {windowVisibility.container1 && delayedVisibility.container1 && (
        <div
          className="window container-window containerspawn"
          ref={containerRefs.container1}
          style={getWindowStyle("container1")}
        >
          <div className="title-bar" onMouseDown={handleMouseDown1}>
            <div className="title-bar-text">ETHERVISTA.exe</div>
            <div className="title-bar-controls">
              <button
                aria-label="Close"
                onClick={() => toggleWindow("container1")}
              ></button>
            </div>
          </div>
          <p className="unselectable EtherVistaSubTitle">
            EtherVista : The new standard for DEXs
          </p>
          <SnackbarProvider maxSnack={3}>
            {web3ProviderContent}
          </SnackbarProvider>
        </div>
      )}

      {showBackgroundImage && (
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${lightbg})`,
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}

      {windowVisibility.container2 && delayedVisibility.container2 && (
        <div
          className="window container-window readmore"
          ref={containerRefs.container2}
          style={getWindowStyle("container2")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown2}>
              <div className="title-bar-text">Read More</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container2")}
                ></button>
              </div>
            </div>

            <div className="window lg-paddingsecond">
              <fieldset>
                <legend>We are everywhere!</legend>
                <div className="window-body">
                  <p>
                    Come join us on{" "}
                    <a href="https://twitter.com/ethervista" target="#">
                      Twitter
                    </a>
                  </p>
                  <p></p>
                  <p>
                    Come join us on{" "}
                    <a href="https://discord.gg/TnGHTQjRcD" target="#">
                      Discord
                    </a>
                  </p>
                  <p>
                    Come join us on{" "}
                    <a href="https://t.me/EthervistaOfficial" target="#">
                      Telegram
                    </a>
                  </p>
                  <p>
                    Protect yourself from bots{" "}
                    <a
                      href="https://docs.flashbots.net/flashbots-protect/quick-start"
                      target="#"
                    >
                      Flashbots
                    </a>
                  </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container3 && delayedVisibility.container3 && (
        <div
          className="window startmenubox"
          ref={containerRefs.container3}
          style={{
            left: 0,
            top: "calc(100% - 228px)", // This will position it at the bottom
            zIndex: 1,
          }}
        >
          <ul role="menu" className="startmenu">
            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container4")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/media_player_stream_sun4.png"
                  alt="Flashloans"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Flashloans</span>
              </a>
            </li>

            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container6")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/mouse_padlock.png"
                  alt="Flashloans"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Futures</span>
              </a>
            </li>

            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container5")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/regedit-0.png"
                  alt="Flashloans"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Lending</span>
              </a>
            </li>

            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container2")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/help_book_cool_small-4.png"
                  alt="Read more"
                />
                <span>Read more</span>
              </a>
            </li>
          </ul>
        </div>
      )}

      {windowVisibility.container4 && delayedVisibility.container4 && (
        <div
          className="window container-window loans"
          ref={containerRefs.container4}
          style={getWindowStyle("container4")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown4}>
              <div className="title-bar-text">Feeless Flashloans</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container4")}
                ></button>
              </div>
            </div>

            <div className="window lg-paddingsecond">
              <fieldset>
                <legend>Feeless Flashloans</legend>
                <div className="window-body">
                  <p>Coming soon ! </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container5 && delayedVisibility.container5 && (
        <div
          className="window container-window lending"
          ref={containerRefs.container5}
          style={getWindowStyle("container5")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown5}>
              <div className="title-bar-text">Lending.exe</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container5")}
                ></button>
              </div>
            </div>

            <div className="window lg-paddingsecond">
              <fieldset>
                <legend>Lending</legend>
                <div className="window-body">
                  <p>Coming soon ! </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container6 && delayedVisibility.container6 && (
        <div
          className="window container-window futures"
          ref={containerRefs.container6}
          style={getWindowStyle("container6")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown6}>
              <div className="title-bar-text">Futures.exe</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container6")}
                ></button>
              </div>
            </div>

            <div className="window lg-paddingsecond">
              <fieldset>
                <legend>Futures</legend>
                <div className="window-body">
                  <p>Coming soon ! </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container7 && delayedVisibility.container7 && (
        <div
          className="window container-window futures"
          ref={containerRefs.container7}
          style={getWindowStyle("container7")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown7}>
              <div className="title-bar-text">SUPERCHAT.exe</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container7")}
                ></button>
              </div>
            </div>

            <div className="window lg-paddingsecond">
              <fieldset>
                <legend>
                  {" "}
                  <img
                    src={superchat}
                    height={"15px"}
                    width={"143px"}
                    alt="superchat"
                  />
                </legend>
                <div className="window-body">
                  <LiveChat network={networkState} />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container8 && delayedVisibility.container8 && (
        <div
          className="window container-window futures"
          ref={containerRefs.container8}
          style={getWindowStyle("container8")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown8}>
              <div className="title-bar-text">EXPLORER.exe</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container8")}
                ></button>
              </div>
            </div>

            <div className="window lg-paddingsecond">
              <Analytics network={networkState} />
            </div>
            
          </div>
        </div>
      )}

      <div className="taskbar outward-bevel unselectable" id="Taskbar" style={{ zIndex: 10 }}>
        <div className="taskbar-left">
          <button className="startbtn windowsbutton" onClick={() => toggleWindow("container3")}>
            <img src={ethericon} height={"24px"} width={"24px"} alt="Windows Logo" />
            <span>Start</span>
          </button>
          <button className="startbtn" onClick={() => toggleWindow("container1")}>
            <img src="https://win98icons.alexmeub.com/icons/png/network_normal_two_pcs-0.png" height={"24px"} width={"24px"} alt="Wallet" />
            <span>ROUTER</span>
          </button>
          <button className="startbtn" onClick={() => toggleWindow("container7")}>
            <img src="https://win98icons.alexmeub.com/icons/png/message_file-0.png" alt="SuperChat" height={"24px"} width={"24px"} />
            <span>SuperChat</span>
          </button>
          <button className="startbtn" onClick={() => toggleWindow("container8")}>
            <img src="https://win98icons.alexmeub.com/icons/png/internet_connection_wiz-0.png" alt="Analytics" height={"24px"} width={"24px"} />
            <span>EXPLORER</span>
          </button>
          <button className="startbtn" onClick={toggleBackgroundImage}>
            <img src="https://win98icons.alexmeub.com/icons/png/paint_old-0.png" alt="theme" height={"24px"} width={"24px"} />
          </button>
        </div>
        <div className='taskbar-right'>
        <ConnectionStatus network={networkState} />
        </div>
      </div>
    </>
  );
};

export default App;
