import { Contract, ethers } from "ethers";
import * as chains from "./constants/chains";
import COINS from "./constants/coins";
import { Face, LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { uriTransformer } from "react-markdown";

const ROUTER = require("./build/UniswapV2Router02.json"); //Renew done
//go the remix artifacts search for EthevistaRouter.json and get only the "ABI"
//add this to ./build/UniswapV#Router02
const ERC20 = require("./build/ERC20.json"); //DO NOT CHANGE Erc20 token not lp
const FACTORY = require("./build/IUniswapV2Factory.json"); //OK not necessary renew
const PAIR = require("./build/IUniswapV2Pair.json"); //Renew done

const TOKEN_FACTORY = require("./build/TokenFactory.json");
export async function create(name, symbol, supply, vistaOnly, signer) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  let safeFactory;
  switch (chainId) {
    case 1:
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0";
      break;
    case 8453:
      safeFactory = "0x273126bA6fb9C8E28CA47e50FdcEd6844994659c"
      break;
    case 42161:
      safeFactory = "0x5b181b1FA1b6cd5914ff31d6E0688a0F06774292"
      break;
    default:
      console.log("NO NETWORK DETECTED DEFAULTING TO ETHEREUM");
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0"
  }

  let safeTokenFactory = new Contract(
    safeFactory,
    TOKEN_FACTORY.abi,
    signer
  );

  let tx = await safeTokenFactory.create(
    name,
    symbol,
    Number(supply).toString(),
    vistaOnly
  );
  const receipt = await tx.wait();
  const event = receipt.events.find(
    (event) => event.event === "safeTokenCreated"
  );
  const address = event.args[0];

  return address;
}

export async function isWhitelisted(address1, address2, signer) {
  let isWhitelisted;
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  console.log("CHAINID SIGNER", chainId);
  let safeFactory;
  let weth;
  switch (chainId) {
    case 1:
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0";
      weth = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
      break;
    case 8453:
      safeFactory = "0x273126bA6fb9C8E28CA47e50FdcEd6844994659c"
      weth = "0x4200000000000000000000000000000000000006";
      break;
    case 42161:
      safeFactory = "0x5b181b1FA1b6cd5914ff31d6E0688a0F06774292";
      weth = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
      break;
    default:
      console.log("NO NETWORK DETECTED DEFAULTING TO ETHEREUM");
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0"
      weth = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  }

  if (address1 == weth) {
    let safeTokenFactory = new Contract(
      safeFactory,
      TOKEN_FACTORY.abi,
      signer
    );
    isWhitelisted = await safeTokenFactory.whitelistedTokens(address2);
    console.log('Is whitelistedd: ', isWhitelisted);
  } else {
    let safeTokenFactory = new Contract(
      safeFactory,
      TOKEN_FACTORY.abi,
      signer
    );
    isWhitelisted = await safeTokenFactory.whitelistedTokens(address1);
    console.log('Is whitelisted', isWhitelisted);
  }
  return isWhitelisted;
}

export function getProvider() {
  return new ethers.providers.Web3Provider(window.ethereum);
}

export function getSigner(provider) {
  return provider.getSigner();
}

export async function getNetwork(provider) {
  const network = await provider.getNetwork();
  return network.chainId;
}

export function getRouter(address, signer) {
  return new Contract(address.toString(), ROUTER.abi, signer);
}

export async function checkNetwork(provider) {
  const chainId = getNetwork(provider);
  if (chains.networks.includes(chainId)) {
    return true;
  }
  return false;
}

export function getWeth(address, signer) {
  return new Contract(address, ERC20.abi, signer);
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}

export async function getAccount() {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  return accounts[0];
}

//This function checks if a ERC20 token exists for a given address
//    `address` - The Ethereum address to be checked
//    `signer` - The current signer
export function doesTokenExist(address, signer) {
  try {
    return new Contract(address, ERC20.abi, signer);
  } catch (err) {
    console.log("token exist err?: ", err);
    return false;
  }
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error setting to 18", error);
      return 18;
    });
  return decimals;
}

// This function returns an object with 2 fields: `balance` which container's the account's balance in the particular token,
// and `symbol` which is the abbreviation of the token name. To work correctly it must be provided with 4 arguments:
//    `accountAddress` - An Ethereum address of the current user's account
//    `address` - An Ethereum address of the token to check for (either a token or AUT)
//    `provider` - The current provider
//    `signer` - The current signer
export async function getBalanceAndSymbol(
  accountAddress,
  address,
  provider,
  signer,
  weth_address,
  coins
) {
  accountAddress = await signer.getAddress();
  try {
    if (address === weth_address) {
      const balanceRaw = await provider.getBalance(accountAddress.toString());
      return {
        balance: ethers.utils.formatEther(balanceRaw),
        symbol: coins[0].abbr,
      };
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const tokenDecimals = await getDecimals(token);
      const balanceRaw = await token.balanceOf(accountAddress.toString());
      const symbol = await token.symbol();

      return {
        balance: balanceRaw * 10 ** -tokenDecimals,
        symbol: symbol,
      };
    }
  } catch (error) {
    console.log("The getBalanceAndSymbol function had an error!");
    console.log(error);
    return false;
  }
}

// This function swaps two particular tokens / AUT, it can handle switching from AUT to ERC20 token, ERC20 token to AUT, and ERC20 token to ERC20 token.
// No error handling is done, so any issues can be caught with the use of .catch()
// To work correctly, there needs to be 7 arguments:
//    `address1` - An Ethereum address of the token to trade from (either a token or AUT)
//    `address2` - An Ethereum address of the token to trade to (either a token or AUT)
//    `amount` - A float or similar number representing the value of address1's token to trade
//    `routerContract` - The router contract to carry out this trade
//    `accountAddress` - An Ethereum address of the current user's account
//    `signer` - The current signer
export async function swapTokens( //add slippage uint, //need two new components Slippage text and Fee button EIP1559??
  address1, //ESTIMATE GAS???
  address2,
  amount,
  routerContract,
  accountAddress,
  signer,
  factory,
  slippage
) {
  accountAddress = await signer.getAddress();

  const tokens = [address1, address2];
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const token1 = new Contract(address1, ERC20.abi, signer);
  const tokenDecimals = await getDecimals(token1);

  const amountIn = ethers.utils.parseUnits(amount, tokenDecimals);
  const amountOut = await routerContract.callStatic.getAmountsOut(
    amountIn,
    tokens
  );

  const wethAddress = await routerContract.WETH();

  const pairAddress = await factory.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);

  if (address1 === wethAddress) {
    //buy
    let liqFee = await routerContract.usdcToEth(
      await pairContract.buyTotalFee()
    );

    /* global BigInt */

    console.log(Math.round(amountOut[1] * (1 - slippage / 100)));
    console.log(Math.round(Number(liqFee) + Number(amountIn)));
    await routerContract.swapExactETHForTokensSupportingFeeOnTransferTokens(
      BigInt(Math.round(amountOut[1] * (1 - slippage / 100))), //AmountoutMin: amountOut[1](1-slippage)
      tokens,
      accountAddress,
      deadline,
      { value: BigInt(Math.round(Number(liqFee) + Number(amountIn))) } //.toString()
    );
  } else if (address2 === wethAddress) {
    //sell
    const allowance1 = await token1.allowance(
      accountAddress,
      routerContract.address
    );
    console.log("allowance", allowance1.toString());
    if (Number(allowance1) < amountIn) {
      console.log("approving...");
      let tx = await token1.approve(
        routerContract.address,
        BigInt(amountIn * 2)
      );
      await tx.wait();
    }
    let sellfee = await pairContract.sellTotalFee();
    let liqFee = await routerContract.usdcToEth(sellfee); //totalSellFee actually
    await routerContract.swapExactTokensForETHSupportingFeeOnTransferTokens(
      BigInt(Number(amountIn)), //.toString(),
      BigInt(Math.round(amountOut[1] * (1 - slippage / 100))), //.toString(), //AmoutOutmin: amountOut[1](1-slippage)
      tokens,
      accountAddress.toString(),
      deadline,
      { value: BigInt(Math.round(Number(liqFee) * 1.03)) } //added feature , Number(liqFee).toString()?
    );

    /*
    console.log('liqFee', Number(liqFee)*1.1);
    console.log('amountIN:', Number(amountIn));
    console.log('AmountOutmin', Number(Math.round(amountOut[1] * (1 - slippage / 100))));
    console.log('ETH fee', Number(liqFee).toString() + 1);
    console.log('Deadline', Number(deadline));
    */
  }

  //Missing High priority: swapExactETHForTokensSupportingFeeOnTransferTokens
  //                       swapExactTokensForETHSupportingFeeOnTransferTokens

  //Missing SwapExactTokensForETH
  //        SwapExactETHForTokens
}

//This function returns the conversion rate between two token addresses
//    `address1` - An Ethereum address of the token to swaped from (either a token or AUT)
//    `address2` - An Ethereum address of the token to swaped to (either a token or AUT)
//    `amountIn` - Amount of the token at address 1 to be swaped from
//    `routerContract` - The router contract to carry out this swap
export async function getAmountOut(
  address1,
  address2,
  amountIn,
  routerContract,
  signer
) {
  try {
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);
    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);
    const values_out = await routerContract.getAmountsOut(
      ethers.utils.parseUnits(String(amountIn), token1Decimals),
      [address1, address2]
    );
    const amount_out = values_out[1] * 10 ** -token2Decimals;
    return Number(amount_out);
  } catch {
    return false;
  }
}

// This function calls the pair contract to fetch the reserves stored in a the liquidity pool between the token of address1 and the token
// of address2. Some extra logic was needed to make sure that the results were returned in the correct order, as
// `pair.getReserves()` would always return the reserves in the same order regardless of which order the addresses were.
//    `address1` - An Ethereum address of the token to trade from (either a ERC20 token or AUT)
//    `address2` - An Ethereum address of the token to trade to (either a ERC20 token or AUT)
//    `pair` - The pair contract for the two tokens
export async function fetchReserves(address1, address2, pair, signer) {
  try {
    // Get decimals for each coin
    const coin1 = new Contract(address1, ERC20.abi, signer);
    const coin2 = new Contract(address2, ERC20.abi, signer);

    const coin1Decimals = await getDecimals(coin1);
    const coin2Decimals = await getDecimals(coin2);

    // Get reserves
    const reservesRaw = await pair.getReserves();

    // Put the results in the right order
    const results = [
      (await pair.token0()) === address1 ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1()) === address2 ? reservesRaw[1] : reservesRaw[0],
    ];

    // Scale each to the right decimal place
    return [
      results[0] * 10 ** -coin1Decimals,
      results[1] * 10 ** -coin2Decimals,
    ];
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [0, 0];
  }
}

// This function returns the reserves stored in a the liquidity pool between the token of address1 and the token
// of address2, as well as the liquidity tokens owned by accountAddress for that pair.
//    `address1` - An Ethereum address of the token to trade from (either a token or AUT)
//    `address2` - An Ethereum address of the token to trade to (either a token or AUT)
//    `factory` - The current factory
//    `signer` - The current signer
export async function getReserves(
  address1,
  address2,
  factory,
  signer,
  accountAddress
) {
  accountAddress = await signer.getAddress();
  try {
    const pairAddress = await factory.getPair(address1, address2);
    const pair = new Contract(pairAddress, PAIR.abi, signer);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);
      const liquidityTokens_BN = await pair.balanceOf(accountAddress);
      const liquidityTokens = Number(
        ethers.utils.formatEther(liquidityTokens_BN)
      );

      return [
        reservesRaw[0].toPrecision(6),
        reservesRaw[1].toPrecision(6),
        liquidityTokens,
      ];
    } else {
      console.log("no reserves yet");
      return [0, 0, 0];
    }
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [0, 0, 0];
  }
}

export async function getStats(
  address1,
  routerContract, //props.network.router
  factory, //props.network.factory
  signer
) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);
    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      let usrShare = (await pairContract.viewShare()).toString();
      console.log("Share Rewards: ", usrShare);
      let total = (await pairContract.totalCollected()).toString();
      let pool = (await pairContract.poolBalance()).toString();

      let share = (
        (Number(ethers.utils.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.utils.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = (
        (Number(ethers.utils.formatEther(pool)) * 1) /
        eth_price
      ).toFixed(2);
      //let lockTime = Number(ethers.utils.formatEther(await pairContract.lockTime()));

      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
    } else {
      console.log("no rewards yet");
      return [0, 0, 0];
    }
  } catch (err) {
    console.log(err);
    return [0, 0, 0];
  }
}

function convertTimestampToDate(unixTimestamp) {
  // Create a new Date object with the Unix timestamp (in milliseconds)
  const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds

  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const year = date.getFullYear();

  // Format the date as DD/MM/YYYY
  return `${day}/${month}/${year}`;
}

export async function getTransactions(
  address1,
  signer
) {
  try {
    let routerContract = new Contract("0xEad811D798020c635cf8dD4ddF31bDC5595B09F3".toString(), ROUTER.abi, signer);
    let factory = new Contract("0x9a27cb5ae0B2cEe0bb71f9A85C0D60f3920757B4".toString(), FACTORY.abi, signer);
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);
    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      let total = (await pairContract.totalCollected()).toString();
      let totalCollected = (
        (Number(ethers.utils.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let txns = Number(totalCollected / 5).toFixed(0);

      return txns;
    } else {
      console.log("no rewards yet");
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
}

export async function getMetadata(
  address1,
  routerContract, //props.network.router
  factory, //props.network.factory
  signer
) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);

    //==================
    let metadata = await pairContract.fetchMetadata(); //website/logo/description/chat/social
    let buyFee = await pairContract.buyTotalFee();
    let sellFee = await pairContract.sellTotalFee();
    let total_rewards = await pairContract.totalCollected();
    let creator = await pairContract.creator();
    let creation = await pairContract.creation_time();
    let creation_date = convertTimestampToDate(creation);
    //==================
    let renounced = creator == 0x0000000000000000000000000000000000000000;
    //console.log('CREATION:', creation);
    const token = new Contract(address1, ERC20.abi, signer);
    const symbol = await token.symbol();
    const name = await token.name();
    const supply = (await token.totalSupply()) / 10 ** 18;

    const priceData = await getPriceData(
      address1,
      routerContract,
      pairContract,
      signer,
      supply
    );

    //now must return creation_date
    return {
      creation_date,
      metadata,
      symbol,
      name,
      supply,
      renounced,
      totalRewardsUSD: Number(total_rewards) / (priceData.eth_price * 10 ** 18),
      sellFee,
      buyFee,
      ...priceData,
    };
  } catch (err) {
    console.log(err);
    console.log("error fetch metadata");
  }
}

async function getPriceData(
  address1,
  routerContract,
  pairContract,
  signer,
  supply
) {
  const coin1 = new Contract(address1, ERC20.abi, signer);
  const coin1Decimals = await coin1.decimals();
  const coin2Decimals = 18;

  let reservesRaw = await pairContract.getReserves();
  const results = [
    (await pairContract.token0()) === address1
      ? reservesRaw[0]
      : reservesRaw[1],
    (await pairContract.token1()) === (await routerContract.WETH())
      ? reservesRaw[1]
      : reservesRaw[0],
  ];

  const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;
  let price = (
    (((results[1] * 10 ** -coin2Decimals) /
      (results[0] * 10 ** -coin1Decimals)) *
      1) /
    eth_price
  ).toFixed(5);

  let marketcap = price * supply;
  const total_liquidity = (
    results[0] * 10 ** -coin1Decimals * price +
    (results[1] * 10 ** -coin2Decimals * 1) / eth_price
  ).toFixed(2);

  return {
    price,
    marketcap,
    total_liquidity,
    eth_price,
  };
}

export async function getLivedata(address1, routerContract, factory, signer) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);

    let total_rewards = await pairContract.totalCollected();

    const coin1 = new Contract(address1, ERC20.abi, signer);
    const coin1Decimals = await coin1.decimals();
    const coin2Decimals = 18;

    let reservesRaw = await pairContract.getReserves();
    const results = [
      (await pairContract.token0()) === address1
        ? reservesRaw[0]
        : reservesRaw[1],
      (await pairContract.token1()) === address2
        ? reservesRaw[1]
        : reservesRaw[0],
    ];
    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18; //eth/$
    let price = (
      (((results[1] * 10 ** -coin2Decimals) /
        (results[0] * 10 ** -coin1Decimals)) *
        1) /
      eth_price
    ).toFixed(5);
    const token = new Contract(address1, ERC20.abi, signer);
    const supply = (await token.totalSupply()) / 10 ** 18;
    console.log("supply", supply);
    let marketcap = price * supply;
    const total_liquidity = (
      results[0] * 10 ** -coin1Decimals * price +
      (results[1] * 10 ** -coin2Decimals * 1) / eth_price
    ).toFixed(2);

    return {
      supply,
      price,
      totalRewardsUSD: Number(total_rewards) / (eth_price * 10 ** 18),
      marketcap,
      total_liquidity,
    };
  } catch (err) {
    console.log(err);
    console.log("error fetch metadata");
    return null; // or an appropriate error object
  }
}

export async function getFees(
  address1,
  routerContract, //props.network.router
  factory, //props.network.factory
  signer
) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      let sellFee = (await pairContract.sellTotalFee()).toString();
      let buyFee = (await pairContract.buyTotalFee()).toString();

      return [buyFee, sellFee];
    } else {
      console.log("no rewards yet");
      return [0, 0];
    }
  } catch (err) {
    console.log(err);
    return [0, 0];
  }
}

export async function claimRewards(
  address1,
  routerContract, //props.network.router
  signer,
  factory //props.network.factory
) {
  const address2 = await routerContract.WETH();
  const pairAddress = await factory.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);
  if (pairAddress !== "0x0000000000000000000000000000000000000000") {
    await pairContract.claimShare();
  }
}