export const networks = [1,42161,8453];

export const ChainId = {
  MAINNET: 1,
  //SEPOLIA: 11155111,
  BASE: 8453,
  ARBITRUM: 42161,
  //POLYGON: 137,
  //OP: 10,
};

export const routerAddress = new Map();
routerAddress.set(ChainId.MAINNET,"0xEad811D798020c635cf8dD4ddF31bDC5595B09F3");
//routerAddress.set(ChainId.SEPOLIA,"0xb50908869e19791b7795d3f8383cDf139db170cA");
routerAddress.set(ChainId.BASE, "0x018004F8b21b3511d98B1BBFFAeE034F58ae288E");
routerAddress.set(ChainId.ARBITRUM, "0x9472b4b3B088B18473f673E22fe631796D529360");
//routerAddress.set(ChainId.POLYGON, "0x07a1905D44feeA439ceFAabd11a63bEf684abE11");
//routerAddress.set(ChainId.OP, "0x0F44AC51198D8F99847db6C431448dBC673428f1");
